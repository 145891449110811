<template>
  <div class="goodsIndexManage">
    <div class="facilityBox">
      <el-form class="searchForm" :model="searchForm" :inline="true" ref="searchForm" label-width="90px">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input v-model="searchForm.EnterpriseFullName" placeholder="请输入企业名称"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="签订日期：" prop="field3">
          <el-date-picker v-model="searchForm.dateTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="180"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CreatedTime" label="签订日期" width="160"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goSign(scope.row)" icon="el-icon-document">电签</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 选择电签 -->
    <el-dialog :visible.sync="rateMdel" width="50%" append-to-body>
      <span slot="title">选择电签</span>
      <div class="dialog_box">
        <div style="text-align: center;">
          <strong>企业名称：</strong><u>{{ selectUserInfo.EnterpriseFullName }}</u><span>（{{ fadadaStatus == 100 ? '已认证' : '未认证'
          }}）</span><br />
        </div>
        <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
          max-height="500px">
          <el-table-column align="center" label="发票-业务-场景">
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneName">
                {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
                {{ scope.row.TaskSceneName }}</span>
              <span v-else> {{ scope.row.InvoiceTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="addSign(scope.row)"
                :disabled="fadadaStatus != 100">发起电签</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 选择电签时间 -->
    <el-dialog :visible.sync="chooseTimeDialog" width="25%" append-to-body v-if="chooseTimeDialog">
      <span slot="title">选择电签日期</span>
      <div style="display: flex;align-items:center;padding-bottom: 30px;">
        电签日期：
        <el-date-picker v-model="signDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
        </el-date-picker>
        <el-button type="primary" size="small" @click="toSubmitSign" style="margin-left: 15px;">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetPartnersBySupplier, GetOwnerRate } from '@/api/newRate'
import { EContractSignAdd, GetFadadaStatus } from '@/api/common/common'
import { _getUserId } from "@/utils/storage";
export default {
  data() {
    return {
      // 搜索参数
      searchForm: {
        EnterpriseFullName: '', //企业名称
        dateTime: ['', ''], //合作起始日期
      },
      //表格数据
      tableData: [],
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      loading: true,
      rateMdel: false,
      rateData: [],
      selectUserInfo: {},
      selectInfo: {},
      signDate: new Date(),
      chooseTimeDialog: false,
      fadadaStatus: null
    }
  },
  created() {
    // 获取列表数据
    this.equipments()
  },
  methods: {
    // 获取法大大用户状态
    getFadadaStatus() {
      GetFadadaStatus({ userId: this.selectUserInfo.UserID }).then((res) => {
        this.fadadaStatus = res.data.Status
      })
    },
    // 去电签
    goSign(item) {
      this.selectUserInfo = item
      this.getFadadaStatus()
      GetOwnerRate({ userid: item.UserID }).then((res) => {
        if (res.data.length == 0) {
          this.$message.error("该买家暂未设置费率");
          return;
        } else {
          let list = [];
          res.data.forEach((item) => {
            list.push(item);
          });
          this.rateData = list;
        }
        this.rateMdel = true;
      });
    },
    // 发起电签
    addSign(item) {
      this.selectInfo = item
      this.$confirm("确定发起电签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.chooseTimeDialog = true
        this.signDate = new Date()
      });
    },
    toSubmitSign() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      EContractSignAdd({
        taskSceneTypeID: this.selectInfo.TaskSceneTypeID,
        aUserID: this.selectUserInfo.UserID,
        bUserID: parseInt(_getUserId()) / 5,
        signDate: this.signDate,
        pctType: 1,
        rate: this.selectInfo.Rate,
        tecRate: this.selectInfo.TecRate,
        defaultRate: this.selectInfo.DefaultRate,
      })
        .then(() => {
          this.$message.success("发起电签成功!");
        })
        .finally(() => {
          this.chooseTimeDialog = false
          this.rateMdel = false;
          loading.close();
        });
    },
    //搜索
    search() {
      this.pagination.page = 1
      this.equipments()
    },
    //清空
    resetForm() {
      this.searchForm = {
        EnterpriseFullName: '', //企业名称
        dateTime: ['', ''], //合作起始日期
      }
      this.search()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e
      this.equipments()
    },
    // 获取数据列表
    equipments() {
      this.loading = true;
      let params = {
        enterpriseFullName: this.searchForm.EnterpriseFullName,
        createdTimeStart: (this.searchForm.dateTime && this.searchForm.dateTime[0]) ? this.searchForm.dateTime[0] + ' 00:00:00' : '',
        createdTimeEnd: (this.searchForm.dateTime && this.searchForm.dateTime[1]) ? this.searchForm.dateTime[1] + ' 23:59:59' : '',
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        userID: parseInt(_getUserId()) / 5
      }
      GetPartnersBySupplier(params).then(res => {
        this.tableData = res.data.DataList;
        this.pagination.total = res.data.TotalCount;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsIndexManage {
  .facilityBox {
    border-radius: 0px;
    padding: 0px;
    max-height: 76vh;
    box-shadow: none;
  }

  .facilityBox::-webkit-scrollbar {
    width: 6px;
    height: 15px;
  }

  .facilityBox::-webkit-scrollbar-thumb {
    background: #ddd;
  }
}

.el-form-item__label {
  color: #000;
}

.el-form-item {
  margin-bottom: 14px;
}

.dialog_box {
  font-size: 13px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 5%;
  box-sizing: border-box;
  color: #666;
  border-radius: 12px;

  span {
    border-bottom: 1px solid #666;
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #eee;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
  text-align: center;
}

::v-deep .el-dialog__body {
  padding: 20px;
}
</style>